import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser"
import Gallery from "@browniebroke/gatsby-image-gallery"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col, Image, Badge } from "react-bootstrap";



const BlogPortfolioTemplate = ({ data: { previous, next, post } }) => {
  let coverImg, gallery = "";

  post?.portfoliodetails?.coverimage?.localFile.publicURL && ( coverImg = post?.portfoliodetails?.coverimage?.localFile.publicURL )
  post.portfoliodetails?.gallery &&  (gallery = post.portfoliodetails?.gallery.map( node  => ({
    full: node.localFile.childImageSharp.full,
    thumb: node.localFile.childImageSharp.thumb,
    caption: node.altText,
    title: node.altText
  })))

  const onClose = () => {
    console.log('Lightbox was closed')
  }
  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} img={post.featuredImage.node.localFile.publicURL} />
      <Container className="part-portfolio-details"> 
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >  <header>
          <Row className="header" style={{backgroundImage:`url(${coverImg})`, backgroundRepeat: `no-repeat`, backgroundAttachment:'fixed', backgroundPosition: 'top center'}}>
              <Col><h1 itemProp="headline">{parse(post.title)}</h1></Col>
          </Row>
        </header>
        <Row>
          <Col md={4} lg={4}>
            <Row className="portfolio-info">
              <Col xs={12} md={12} lg={12}><h3 className="underlined">Info</h3></Col>
              <Col xs={12} md={6}>
                  <b>Date</b>
                  <p>{post.portfoliodetails.date}</p>
              </Col>
              <Col xs={12} md={6}>
                  <b>Platforms</b>
                  <p>{post.portfoliodetails.platforms}</p>
              </Col>
              {post.portfoliodetails?.clients && (
                <Col  xs={12} lg={12}>
                  <b>Clients</b>
                  <p>{post.portfoliodetails.clients}</p>
                </Col>
              )}
              {post.portfoliodetails?.url && (
                <Col  xs={12} lg={12}>
                  <b>URL</b>
                  <p><a target="_blank" rel="noreferrer" href={post.portfoliodetails.url}>{post.portfoliodetails.url}</a></p>
                </Col>
              )}
            </Row>
            <Row className="part-skills">
              <Col xs={12} md={12} lg={12}><h3 className="underlined">Technology</h3></Col>
                {post.portfoliodetails.skills.map((skill, index) =>(
                    <Col key={`skill-${index}`} md={3} xs={3} className="text-center">
                    {skill.localFile.childImageSharp?.gatsbyImageData ?  (
                        <GatsbyImage image={skill.localFile.childImageSharp.gatsbyImageData} alt={skill.alt} />
                    ):(
                        <Image src={skill.localFile.publicURL} alt={skill.altText} width="48px"/>
                    )}
                    <p>{skill.altText}</p>
                  </Col>
                ))}
            </Row>
            <Row className="part-skills">
            {post.tags.nodes.length > 0 && ( <>
              <Col xs={12} md={12} lg={12}><h3 className="underlined">Other Technology</h3></Col>
              <Col>
              {post.tags.nodes.map((node, index) => (
                <React.Fragment key={`tag-${index}`}>
                <Badge bg="danger">{node.name}</Badge>
                </React.Fragment>
              ))}
              </Col>
              </>
              )}
            </Row>
          </Col>
          <Col md={8} lg={8} className="portfolio-description">
          {!!post.content && (
            <section itemProp="articleBody">{parse(post.content)}</section>
          )}
          <Row>
          {post.portfoliodetails?.images  && (
              <Col xs={12} lg={12} className="mt-4 text-center">
                {post.portfoliodetails?.images.map((image, index) => (
                  <React.Fragment key={`image-${index}`}>
                  {image.localFile.childrenImageSharp?.gatsbyImageData ?  (
                    <GatsbyImage image={image.localFile.childrenImageSharp.gatsbyImageData} alt={image.alt} />
                  ):(
                    <Image src={image.localFile.publicURL} alt={image.altText} fluid/>
                  )}
                  </React.Fragment>
                ))}
              </Col>
            )}
          </Row>
          </Col>
        </Row>
        <Row> 
            {gallery.length > 0 && (
              <>
              <Col xs={12} lg={12}><h3 className="underlined">Project View</h3></Col>
              <Col className="project-gallery">
                <Gallery images={gallery}  onClose={onClose} /> 
              </Col>
              </>
            )}
        </Row>
      </article>
      <Row className="nav-bottoms">
        <Col xs={12} lg={6}>
          {previous && (
              <Link to={previous.uri} rel="prev">
                ← {parse(previous.title)}
              </Link>
          )}
        </Col>
        <Col xs={12} lg={6} className="text-right">
        {next && (
              <Link to={next.uri} rel="next">
                {parse(next.title)} →
              </Link>
            )}
        </Col>
      </Row>
      </Container>
    </Layout>
  )
}

export default BlogPortfolioTemplate

export const pageQuery = graphql`
  query BlogPortfolioById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      tags {
        nodes {
          name
          slug
          link
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
      portfoliodetails {
        clients
        date
        platforms
        url
        skills {
          altText
          localFile {
            publicURL
            childrenImageSharp {
              gatsbyImageData
            }
          }
        }
        coverimage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
            }
            publicURL
          }
        }
        images {
          localFile {
            childrenImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
            }
            publicURL
          }
        }
        gallery {
          altText
          localFile {
            childImageSharp {
              thumb: gatsbyImageData (
                width: 350
                height: 250
                placeholder: BLURRED
              )
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
            publicURL
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
